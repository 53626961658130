import React from 'react';
import { navigate } from 'gatsby';

const SubscribeInsta = () => {
  React.useEffect(function() {
    navigate('/subscribe');
  });
  return null;
};

export default SubscribeInsta;
